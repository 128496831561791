import logo from './logo.svg';
import './App.css';
import YellowText from './svg/yellow_text.svg';
import BlueText from './svg/blue_text.svg';
import Countdown from './components/Countdown';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className="container container-yellow">
      <Analytics/>
      <div className="title">East Anglia is</div>
      <img src={YellowText} className="color-text"/>
      <Countdown/>
    </div>
  );
}

export default App;
