import React, { useState } from "react";
import moment from "moment";
import CountdownPart from "./CountdownPart";
import useInterval from "../hooks/useInterval";

const sinceDate = moment("2010-11-28 18:00:00");
//const sinceDate = moment("2023-11-11:18:00");

export default() => {

    useInterval(
        () => {
          // Your custom logic here
          setDuration(calculateDuration());
        },
        // Delay in milliseconds or null to stop it
        1000
    );

    const calculateDuration = () => {
        const now = moment();
        return moment.duration(now.diff(sinceDate));
    };

    const [duration, setDuration] = useState(calculateDuration());

    

    const getDurationParts = () => {
        // Write a method that returns an object with the number of years, months, days, hours, and minutes
        // since a date. Make sure that each is subtracted from the next largest unit.

        // For example, if the date is 2018-01-01, and the current date is 2020-02-02, the object should be:
        // {
        //     years: 2,
        //     months: 1,
        //     days: 1,
        //     hours: 0,
        //     minutes: 0
        // }
        // Hint: use moment.js (https://momentjs.com/docs/#/displaying/difference/)

        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        return {
            years,
            months,
            days,
            hours,
            minutes
        };

        

    };

    const parts = getDurationParts();

    return (
        <div className="countdown-container">
            <CountdownPart label="Years" value={parts.years}/>
            <div className="separator">:</div>
            <CountdownPart label="Months" value={parts.months}/>
            <div className="separator">:</div>
            <CountdownPart label="Days" value={parts.days}/>
            <div className="separator">:</div>
            <CountdownPart label="Hours" value={parts.hours}/>
            <div className="separator">:</div>
            <CountdownPart label="Mins" value={parts.minutes}/>
        </div>
    );
};