import React from "react";

export default ({value, label}) => {

    const getDigits = () => {
        // The value will be a number between 0 and 99
        // Return an array of two strings, one for each digit
        // If the number is less than 10, make sure to prepend a 0
        const digits = [];
        
        if (value < 10) {
            digits.push("0");
            digits.push(value.toString());
        } else {
            digits.push(value.toString()[0]);
            digits.push(value.toString()[1]);
        }
        
        return digits;
    };

    const digits = getDigits();

    return (
        <div className="countdown-part-container">
            <div className="countdown-part-row">
                <div className="countdown-part-1">
                    {digits[0]}
                </div>
                <div className="countdown-part-2">
                    {digits[1]}
                </div>
            </div>
            <div className="countdown-label">
                {label}
            </div>
            
        </div>
    )
};